import { Link as RouterLink } from "react-router-dom";
// @mui
import { Icon } from "@iconify/react";
import { Box, Link, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// auth
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSubDistributor } from "src/api/distributor-api";
import Label from "src/components/label";
import { CustomAvatar } from "../../../components/custom-avatar";

import { jwtDecode } from "src/auth/utils";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  // get the accessToken from localStorage
  const accessToken = localStorage.getItem("accessToken");

  // decode the accessToken
  const decoded = jwtDecode(accessToken);
  const user = {
    username: decoded.username,
  }

  const selectedRole = useSelector((state) => state.auth.selectedRole);

  const [subDistributor, setSubDistributor] = useState();
  useEffect(() => {
    if (selectedRole.role === "sub-distributor" || selectedRole.role === "agent") {
      async function fetchSubDistributor() {
        try {
          const response = await getSubDistributor(selectedRole.distributor_i_customer, selectedRole.sub_distributor_id);
          if (response.status_code === 1001) {
            setSubDistributor(response.data);
          }
        } catch (error) {
          console.error("Error fetching sub distributor:", error);
        }
      }
      fetchSubDistributor();
    }
  }, [selectedRole.distributor_i_customer, selectedRole.role, selectedRole.sub_distributor_id]);

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.user.account} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar alt={selectedRole.customer_name} name={""}>
          <Icon icon="eva:person-fill" />
        </CustomAvatar>

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {/* if user.type is admin show username */}
            {selectedRole.role === "admin" ? user?.username : selectedRole.customer_name}
          </Typography>
          {subDistributor && <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>{subDistributor.name}</Typography>}
          {selectedRole.role !== 'sub-distributor' && <Label
            variant="soft"
            color={
              (selectedRole.role === "admin" && "primary") ||
              (selectedRole.role === "distributor" && "secondary") ||
              (selectedRole.role === "sub-distributor" && "success") ||
              (selectedRole.role === "support_agent" && "success") ||
              (selectedRole.role === "iot" && "info") ||
              (selectedRole.role === "agent" && "warning") ||
              "info"
            }
            sx={{ mt: 0.5, fontSize: '0.7rem', my: 0 }}
          >
            {selectedRole.role === "support_agent" ? "Support Agent" : selectedRole.role}
          </Label>}
        </Box>
      </StyledRoot>
    </Link>
  );
}
