import { Card, Divider, Grid, InputAdornment, Tab, Table, TableBody, TableContainer, Tabs, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { listAllVideos } from "src/api/megs-api-api";

import { useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import Label from "src/components/label";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Scrollbar from "src/components/scrollbar";
import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import VideoTableRow from "./VideoTableRow";
export default function TrainingVideosList() {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [videosDataForTabs, setVideosDataForTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState({ id: "All", name: "All" });
  const [activeCount, setActiveCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [tabStatus, setTabStatus] = useState("all");

  const getLengthByStatus = (type) => videosDataForTabs.filter((item) => item.category_type === type).length;

  const TABLE_HEAD = [
    { id: "name", label: "Name", align: "left" },
    { id: "description", label: "Description", align: "left" },
    { id: "brand", label: "Brand", align: "left" },
    { id: "category_name", label: "Category Name", align: "left" },
    { id: "", label: "Actions", align: "left" },
  ];

  const TABS = [
    { value: "all", label: "All", color: "primary", count: videosDataForTabs.length },
    { value: "agent", label: "Agent Videos", color: "success", count: getLengthByStatus("agent") },
    { value: "consumer", label: "Consumer Videos", color: "primary", count: getLengthByStatus("consumer") },
  ];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    async function fetchVideos() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await listAllVideos();
        console.log("response", response);
        if (response.status_code !== 1001) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setVideos(data);
        setVideosData(data);
        setVideosDataForTabs(data);
      } catch (error) {
        // console.log("error", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchVideos();
  }, []);

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleReload = () => {
    async function fetchVideos() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await listAllVideos();
        if (response.status_code !== 1001) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setVideos(data);
        setVideosData(data);
        setVideosDataForTabs(data);
      } catch (error) {
        // console.log("error", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchVideos();
  };

  //   const handleTypeChange = (event, newValue) => {
  //     setSelectedType(newValue);
  //   };

  //   const handleViewRow = (id) => {
  //     navigate(PATH_DASHBOARD.videos.viewProduct(id));
  //   };

  const handleTabStatus = (event, newValue) => {
    setTabStatus(newValue);
  };

  useEffect(() => {
    const filteredVideosData = videosData?.filter((video) => {
      const isStatusMatch =
        tabStatus === "all" || (tabStatus === "agent" && video?.category_type === "agent") || (tabStatus === "consumer" && video?.category_type === "consumer");

      const isSearchMatch =
        video?.name?.toLowerCase().includes(search.toLowerCase()) ||
        video?.description?.toLowerCase().includes(search.toLowerCase()) ||
        video?.category_name?.toLowerCase().includes(search.toLowerCase()) ||
        video?.brand?.toLowerCase().includes(search.toLowerCase());

      return isStatusMatch && isSearchMatch;
    });

    setVideos([...filteredVideosData]);
  }, [search, tabStatus, videosData]);

  if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          value={tabStatus}
          onChange={handleTabStatus}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Label color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />
        <Grid container spacing={2}>
          {/* <Grid item sx={{ m: 2 }} xs={4}>
            <Autocomplete
              value={selectedType}
              onChange={handleTypeChange}
              options={productTypes}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => <TextField {...params} label="Select Product Type" placeholder="Select Product Type" fullWidth />}
            />
          </Grid> */}
          <Grid item sx={{ m: 2 }} xs={12}>
            <TextField
              fullWidth
              value={search}
              placeholder="Search..."
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={videos.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {videos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((video, index) => (
                  <VideoTableRow key={index} row={video} handleReloadVideoData={handleReload} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, videos.length)} />
                <TableNoData isNotFound={videos.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={videos.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
