import PropTypes from "prop-types";
// @mui
import { Link, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist";
import { getDistributorDetails } from "src/api/distributor-api";
import Label from "src/components/label";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";

// ----------------------------------------------------------------------

EscalationTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function EscalationTableRow({ row, selected, onViewRow }) {
  const { id, distributor_i_customer, app_user, i_customer, msisdn, title, description, priority, status } = row;

  const queryInfo = useQuery({
    queryKey: ["distributorDetails", distributor_i_customer],
    queryFn: () => getDistributorDetails(distributor_i_customer),
    staleTime: 1000 * 60 * 60, // Cache for 60 minutes
  });

  const { data: distributorDetails, isFetching } = queryInfo;

  useEffect(() => {
    if (isFetching) {
      console.log("Fetching data from the server...");
    } else {
      console.log("Using cached data.");
    }
  }, [isFetching]);

  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell>
          <Typography variant="subtitle1" noWrap>
            {title}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" noWrap>
            {app_user}
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {msisdn !== null ? msisdn : "-"}
          </Typography>
          {selectedRole.distributor_i_customer === 0 && (
            <Typography variant="caption" noWrap>
              {truncateString(distributorDetails?.data.company_name, 25)}
            </Typography>
          )}
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {description}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {priority}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Label variant="soft" color={(status === "open" && "success") || (status === "closed" && "error") || "default"}>
            <Typography variant="subtitle2" noWrap>
              {status}
            </Typography>
          </Label>
        </TableCell>
      </Link>
    </>
  );
}
