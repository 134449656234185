import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { getBatchRequests } from "src/api/megs-api-api";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useSettingsContext } from "src/components/settings";
import BatchRequestList from "src/sections/@dashboard/subscriptions/BatchRequestList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const BatchRequestPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { distributor_i_customer: i_customer } = selectedRole;
  const [isLoading, setIsLoading] = useState(false);
  const [batchRequestsData, setBatchRequestsData] = useState([]);

  useEffect(() => {
    async function fetchBatchRequests() {
      setIsLoading(true);
      try {
        const response = await getBatchRequests(i_customer);
        if (response.status_code === 1001) {
          setBatchRequestsData(response.data);
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
      setIsLoading(false);
    }
    fetchBatchRequests();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <>
          <Helmet>
            <title>Batch Requests</title>
          </Helmet>

          <Container maxWidth={themeStretch ? false : "xl"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <CustomBreadcrumbs
                  heading="Batch Requests"
                  links={[{ name: "Dashboard", href: PATH_DASHBOARD.subscriptions.subscriptionRequests }, { name: "Batch Requests" }]}
                />
              </Grid>
            </Grid>

            <BatchRequestList batchData={batchRequestsData} />
          </Container>
        </>
      )}
    </>
  );
};

export default BatchRequestPage;
