import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, Tabs, Tab, Box, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import FormProvider, { RHFTextField, RHFSelect, RHFAutocomplete, RHFDatePicker, RHFRadioGroup, RHFMultiCheckbox } from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { isBefore, isSameDay } from "date-fns";
import { createIotRule, getIotCustomers } from "src/api/iot-api";
import { useSnackbar } from "../../../../components/snackbar";
import { getProductType, getProductList } from "src/api/iot-api";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { getDistributorList } from "src/api/distributor-api";
import { PRE_CONFIGURED_THRESHOLD_LIMIT } from "src/config-global";

const IotAddRuleDialog = ({ open, handleClose, handleConfirm }) => {
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { distributor_i_customer: i_customer, role } = selectedRole;
  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState("threshold");
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  // const [productTypeOptions, setProductTypeOptions] = useState([
  //   { label: "Data", value: "data" },
  //   { label: "Airtime", value: "airtime" },
  //   { label: "SMS", value: "sms" },
  //   { label: "Bundle", value: "bundle" },
  // ]);
  const [packages, setPackages] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const WEEKDAYS_OPTIONS = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  const validationSchema = Yup.object({
    ...(role == "admin" && {
      distributor: Yup.string()
        .required("Distributor is required")
        .test("distributor", "Please select a Distributor", (value) => value == null || value !== defaultValues.distributor),
    }),
    rule_name: Yup.string().required("Rule Name is required"),
    rule_description: Yup.string().required("Rule Description is required"),
    ...(tabValue == "schedule_rule" && {
      rule_start_date: Yup.date()
        .required("Rule Start Date is required")
        .test("rule_start_date", "Rule Date is not valid", (value) => isDateError()),
      rule_end_date: Yup.date()
        .test("rule_end_date", "Rule Date is not valid", (value) => isDateError())
        .nullable(),
      rule_repeat_day_frequency: Yup.string().when("reference_rule_time", {
        is: "daily",
        then: (schema) =>
          schema.required("Please enter the number of days").test("rule_repeat_day_frequency", "Please enter a valid number of days", (value) => {
            return value != null && Number(value) > 0;
          }),
        otherwise: (schema) => schema.optional(),
      }),
      rule_repeat_week_frequency: Yup.string().when("reference_rule_time", {
        is: "weekly",
        then: (schema) =>
          schema.required("Please enter the number of weeks").test("rule_repeat_week_frequency", "Please enter a valid number of weeks", (value) => {
            return value != null && Number(value) > 0;
          }),
        otherwise: (schema) => schema.optional(),
      }),
      weekdays_options: Yup.array().when("reference_rule_time", {
        is: "weekly",
        then: (schema) =>
          schema
            .of(
              Yup.string().oneOf(
                WEEKDAYS_OPTIONS.map((day) => day.value),
                `Must be one of: ${WEEKDAYS_OPTIONS.map((day) => day.value).join(", ")}`
              )
            )
            .min(1, "At least select one day is required")
            .required("Must enter the rule days "),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    product_type_option: Yup.string().required("Please select a product type"),
    ...(packages.length > 0 && {
      package: Yup.string()
        .required("Package is required")
        .test("package", "Please select a package", (value) => value == null || value !== defaultValues.package),
    }),
    reference_rule_time: Yup.string().required("Reference Rule Time is required"),
    ...(tabValue == "threshold" && {
      maximum_repetition: Yup.string()
        .required("Please enter a value of maximum repetition")
        .test("maximum_repetition", "Please enter a valid value of maximum repetition", (value) => {
          return value != null && Number(value) > 0;
        }),
    }),
  });

  const defaultValues = {
    rule_name: "",
    rule_description: "",
    rule_start_date: null,
    rule_end_date: null,
    package: "default_select_package",
    distributor: "default_select_distributor",
    product_type_option: null,
    reference_rule_time: "daily",
    rule_repeat_day_frequency: "",
    rule_repeat_week_frequency: "",
    maximum_repetition: "",
    weekdays_options: [],
  };
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
    defaultValues,
    shouldFocusError: true,
  });

  const FILTER_RECURRENCES_RULE = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
  ];

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
  } = methods;

  const values = watch();

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS = [
    { value: "threshold", label: "Threshold", color: "secondry" },
    { value: "schedule_rule", label: "Recurrences Rule", color: "primary" },
  ];

  const isDateError = () => {
    return values.rule_start_date && values.rule_end_date ? isBefore(new Date(values.rule_start_date), new Date(values.rule_end_date)) : true;
  };

  const getSelectedWeekDays = () => {
    const days = values.weekdays_options;
    const dayOrder = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
    };
    // Sort the days based on the custom order
    const sortedDays = days.sort((a, b) => dayOrder[a] - dayOrder[b]);
    return sortedDays.join(", ");
  };

  const onSubmit = async () => {
    let ruleData = {};
    if (tabValue == "threshold") {
      ruleData = {
        name: values.rule_name,
        description: values.rule_description,
        rule_type: "threshold",
        max_recharge_per_month: values.maximum_repetition,
        product_type: values.product_type_option,
        product_id: values.package,
        i_customer: role == "admin" ? values.distributor : i_customer,
      };
    } else if (tabValue == "schedule_rule") {
      ruleData = {
        name: values.rule_name,
        description: values.rule_description,
        rule_type: "schedule_rule",
        product_type: values.product_type_option,
        product_id: values.package,
        start_date: moment(values.rule_start_date).format("YYYY-MM-DD"),
        i_customer: role == "admin" ? values.distributor : i_customer,
        recurrence: {},
        ...(values.rule_end_date && { end_date: moment(values.rule_end_date).format("YYYY-MM-DD") }),
      };
      if (values.rule_repeat_week_frequency != "") {
        ruleData.recurrence = {
          ...ruleData.recurrence,
          weekly: {
            recurrences: Number(values?.rule_repeat_week_frequency),
            weekdays: values?.weekdays_options,
          },
        };
      }
      if (values.rule_repeat_day_frequency != "") {
        ruleData.recurrence = {
          ...ruleData.recurrence,
          daily: {
            recurrences: Number(values?.rule_repeat_day_frequency),
          },
        };
      }
    }

    try {
      setSubmitting(true);
      const { status_code, data } = await createIotRule(ruleData);

      if (status_code == 1001) {
        enqueueSnackbar(data?.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });

        handleClose();
        reset();
      } else {
        enqueueSnackbar(data?.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRuleCancle = () => {
    reset({ ...defaultValues });
    handleClose();
  };

  useEffect(() => {
    if (tabValue == "threshold") {
      methods.setValue("rule_start_date", null);
      clearErrors("rule_start_date");
      methods.setValue("rule_end_date", null);
      clearErrors("rule_end_date");
      methods.setValue("rule_repeat_day_frequency", "");
      clearErrors("rule_repeat_day_frequency");
      methods.setValue("rule_repeat_week_frequency", "");
      clearErrors("rule_repeat_week_frequency");
      methods.setValue("weekdays_options", []);
      clearErrors("weekdays_options");
    } else if (tabValue == "schedule_rule") {
      methods.setValue("maximum_repetition", "");
      clearErrors("maximum_repetition");
    }
  }, [tabValue]);

  useEffect(() => {
    methods.setValue("rule_repeat_day_frequency", "");
    clearErrors("rule_repeat_day_frequency");
    methods.setValue("rule_repeat_week_frequency", "");
    clearErrors("rule_repeat_week_frequency");
    methods.setValue("weekdays_options", []);
    clearErrors("weekdays_options");
  }, [values.reference_rule_time]);

  useEffect(() => {
    const productTypeData = productTypeOptions.find((productType) => productType.value == values.product_type_option);
    (async () => {
      const { status_code, data } = await getProductList(productTypeData?.id);
      if (status_code == 1001) {
        const packageListData = data.map((pkg) => {
          return {
            ...pkg,
            value: pkg.id, // Assuming each package has its own 'id'
            label: `${pkg.product_name} - R ${pkg.price}`,
          };
        });
        setPackages(packageListData);
      }
    })();
    methods.setValue("package", "default_select_package");
    clearErrors("package");
  }, [values.product_type_option]);

  useEffect(() => {
    (async () => {
      const { status_code, data } = await getProductType();
      if (status_code == 1001) {
        const productTypeOptions = data.map((productType) => {
          return { ...productType, value: productType.type.toLowerCase(), label: productType?.type.charAt(0).toUpperCase() + productType?.type.slice(1) };
        });
        setProductTypeOptions(productTypeOptions);
      }
    })();
    (async () => {
      if (role == "admin") {
        const { status_code, data } = await getIotCustomers();
        if (status_code == 1001) {
          const distributorsOptions = data.map((distributor) => {
            return { ...distributor, value: distributor.i_customer, label: distributor.company_name };
          });
          setDistributors(distributorsOptions);
        }
      }
    })();
  }, []);

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
            mb: 1,
            py: 2,
            pb: 1,
          }}
        >
          Top-Up Recurrences
        </DialogTitle>
        <DialogContent sx={{ mb: 1 }}>
          <Box sx={{ pt: 4 }}>
            <Grid container spacing={2}>
              {role == "admin" && (
                <Grid item xs={12} sm={12}>
                  <RHFSelect name="distributor" label="Select Distributor" fullWidth InputLabelProps={{ shrink: true }}>
                    <option value="default_select_distributor" disabled>
                      Select Distributor*
                    </option>
                    {distributors.map((distributor) => (
                      <option key={distributor.value} value={distributor.value}>
                        {distributor.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <RHFTextField type="text" name="rule_name" label="Name of Rule*" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RHFTextField type="text" name="rule_description" label="Rule Description*" multiline={true} />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Card>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabValue}
                    sx={{
                      px: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    {TABS.map((tab) => (
                      <Tab key={tab.value} value={tab.value} label={tab.label} />
                    ))}
                  </Tabs>
                  <Grid container item xs={12} sm={12} padding={2} rowSpacing={4}>
                    {tabValue == "schedule_rule" ? (
                      <>
                        {/* RULE DATES */}
                        <Grid container item columnSpacing={5} xs={12} sx={{ display: "flex" }}>
                          <Grid item xs={6} sm={6}>
                            <RHFDatePicker name="rule_start_date" label="Start On*" defaultValue={"today"} maxDate={"yesterday"} disablePast />
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <RHFDatePicker name="rule_end_date" label="End On" disablePast />
                          </Grid>
                        </Grid>

                        {/* PRODUCT TYPE OPTIONS */}
                        <Grid item xs={12} sm={12}>
                          <Typography variant="subtitle1" color="text.secondary" paragraph>
                            Select Top-up Type :
                          </Typography>
                          <RHFRadioGroup row spacing={4} name="product_type_option" options={productTypeOptions} sx={{ textTransform: "capitalize" }} />
                        </Grid>
                        {/* PRODUCT LIST OPTIONS */}
                        <Grid item xs={12} sm={12}>
                          <RHFSelect
                            name="package"
                            label="Select Package"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            disabled={values.product_type_option === null}
                          >
                            <option value="default_select_package" disabled>
                              Select Package*
                            </option>
                            {packages.map((packageType) => (
                              <option key={packageType.value} value={packageType.value}>
                                {packageType.label}
                              </option>
                            ))}
                          </RHFSelect>
                        </Grid>
                        <Grid container item>
                          <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <RHFRadioGroup column name="reference_rule_time" options={FILTER_RECURRENCES_RULE} />
                          </Grid>
                          <Grid item xs={8} sx={{ display: "flex", justifyContent: "center", width: "full" }}>
                            {values.reference_rule_time === "daily" ? (
                              <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                                <Grid item xs={12}>
                                  <RHFTextField type="text" name="rule_repeat_day_frequency" label="How many days should this rule repeat?*" />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container rowSpacing={1}>
                                <Grid item xs={12}>
                                  <RHFTextField type="text" name="rule_repeat_week_frequency" label="How many weeks should this rule repeat?*" />
                                </Grid>
                                <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <RHFMultiCheckbox row sx={{ width: 100 }} name="weekdays_options" options={WEEKDAYS_OPTIONS} />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        {/* PRODUCT TYPE OPTIONS */}
                        <Grid item xs={12} sm={12}>
                          <Typography variant="subtitle2" color="text.secondary" paragraph>
                            Select Top-up Type :
                          </Typography>
                          <RHFRadioGroup row spacing={4} name="product_type_option" options={productTypeOptions} />
                        </Grid>
                        {/* PRODUCT LIST OPTIONS */}
                        <Grid item xs={12} sm={12}>
                          <RHFSelect
                            name="package"
                            label="Select Package"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            disabled={values.product_type_option === null}
                          >
                            <option value="default_select_package" disabled>
                              Select Package*
                            </option>
                            {packages.map((packageType) => (
                              <option key={packageType.value} value={packageType.value}>
                                {packageType.label}
                              </option>
                            ))}
                          </RHFSelect>
                        </Grid>
                        <Grid container item xs={12} rowSpacing={2}>
                          <Grid item xs={8}>
                            <RHFTextField
                              type="text"
                              name="preConfigureLimit"
                              label="Pre-Configure Limit (MB)"
                              defaultValue={`${PRE_CONFIGURED_THRESHOLD_LIMIT} MB`}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <RHFTextField type="text" name="maximum_repetition" label="Maximum Repetition Per Month*" />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Card>
              </Grid>
              {tabValue == "schedule_rule" && (
                <Grid item xs={12} sm={12} height={30}>
                  {values.reference_rule_time !== "daily" ? (
                    <Typography variant="subtitle2" color="text.secondary" paragraph>
                      This rule will repeat every {values.rule_repeat_week_frequency || "_"} weeks on {getSelectedWeekDays()}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" color="text.secondary" paragraph>
                      This rule will run every {values.rule_repeat_day_frequency || "_"} days
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <DialogActions sx={{ my: 0 }}>
                  <Button variant="contained" onClick={handleRuleCancle} color="error">
                    Cancel
                  </Button>
                  <LoadingButton loading={submitting} disabled={submitting} variant="contained" onClick={handleSubmit(onSubmit)} color="primary">
                    Confirm
                  </LoadingButton>
                </DialogActions>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default IotAddRuleDialog;
