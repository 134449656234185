import PropTypes from "prop-types";
// @mui
import { Link, TableCell, TableRow, Typography } from "@mui/material";
// utils
// components
// import icons from material icons
import moment from "moment";
import Label from "src/components/label";
// ----------------------------------------------------------------------

RuleManagementTableRow.propTypes = {
  row: PropTypes.object,
  ruleType: PropTypes.string,
  handleViewRule: PropTypes.func,
};

export default function RuleManagementTableRow({ row, ruleType, handleViewRule }) {
  return (
    <>
      <Link
        component={TableRow}
        hover
        sx={{ textDecoration: "none", cursor: "pointer" }}
        underline="none"
        onClick={(e) => {
          handleViewRule(e, row?.id);
        }}
      >
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {row?.title}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Label
            variant="soft"
            color={
              (row?.ruleType === "basket_total" && "info") ||
              (row?.ruleType === "individual" && "warning") ||
              (row?.ruleType === "combo" && "default") ||
              "default"
            }
          >
            <Typography variant="subtitle2" noWrap sx={{ textTransform: "capitalize" }}>
              {row?.ruleType === "basket_total"
                ? "Basket"
                : row?.ruleType === "individual"
                  ? "Individual"
                  : row?.ruleType === "combo"
                    ? "Bundle"
                    : row?.ruleType === "variant"
                      ? "Variant"
                      : row?.ruleType === "combo_variant"
                        ? "Bundle Variant"
                        : ""}
            </Typography>
          </Label>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {row?.promoStartDate ? moment(row?.promoStartDate).format("DD-MM-YYYY") : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {row?.promoEndDate ? moment(row?.promoEndDate).format("DD-MM-YYYY") : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Label variant="soft" color={row?.status === "active" ? "success" : "error"}>
            <Typography variant="subtitle2" noWrap>
              {row?.status === "active" ? "Active" : "Inactive"}
            </Typography>
          </Label>
        </TableCell>
      </Link>
    </>
  );
}
