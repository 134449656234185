import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { editAdminUserData, editAppUserData } from "src/api/megs-api-api";
import { FormProvider, RHFAutocomplete, RHFTextField } from "src/components/hook-form";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "src/components/snackbar";
import { LoadingButton } from "@mui/lab";

import * as Yup from "yup";

const EditAppUserDialog = ({ open, handleClose, rowData }) => {
  const [defaultValues, setDefaultValues] = useState();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setDefaultValues({
      name: rowData?.name || "",
      username: rowData?.username || "",
      msisdn: rowData?.msisdn || "",
    });
  }, [open]);

  const AdminDetailsSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    username: Yup.string().required("Email Address is required"),
    msisdn: Yup.string()
      .required("Phone number is required")
      .test("phone", "Phone number is not valid", (value) => typeof value === "string" && isValidPhoneNumber(value, "ZA")),
  });

  const methods = useForm({
    resolver: yupResolver(AdminDetailsSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = methods;

  const values = watch();

  const onProcessSubmit = useCallback(async () => {
    const result = await editAppUserData({
      id: rowData?.id,
      name: values.name,
      email_address : rowData.username,
    //   distributor_i_customer : ,
      msisdn: values.msisdn,
    });

    if (result.status_code === 1001) {
      navigate(PATH_DASHBOARD.admin.appUsers);
      enqueueSnackbar(result.data, { variant: "success" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
      handleClose();
    } else {
      enqueueSnackbar(result?.data?.message, { variant: "error" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    }
  }, [values, methods]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);


  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          mb: 1,
          py: 2,
          pb: 1,
        }}
      >
        Edit Details
      </DialogTitle>
      <DialogContent
        sx={{
          mb: 1,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onProcessSubmit)}>
          <Card sx={{ px: 3, py: 2, my: 2, boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.1)" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <RHFTextField name="name" label="Name and Surname" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <RHFTextField name="username" label="Email Address" InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
              <Grid item xs={12} md={12}>
                <RHFTextField name="msisdn" label="Phone Number" InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                my: 0,
              }}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Cancel
              </Button>
              <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onProcessSubmit)} disabled={errors.length > 0}>
                Save
              </LoadingButton>
            </DialogActions>
          </Card>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default EditAppUserDialog;
