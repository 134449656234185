import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDistributorStores } from "src/api/distribution-api";
import { addAppUserRoles } from "src/api/megs-api-api";
import { FormProvider, RHFAutocomplete, RHFPhoneInput, RHFTextField } from "src/components/hook-form";
import { useSnackbar } from "src/components/snackbar";
import { PATH_DASHBOARD } from "src/routes/paths";
import * as Yup from "yup";

const AddAppUserRole = ({ userData, email, distributor_i_customer }) => {
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { distributor_i_customer: i_customer, sub_distributor_id } = selectedRole;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState();
  const [sub_distributors, setSubDistributors] = useState([]);

  const UserDetailsSchema = Yup.object().shape({
    name: Yup.string(),
    email_address: Yup.string().required("Email Address is required"),
    msisdn: Yup.string(),
    user_phone_number: Yup.object()
      .required("Phone Number is required")
      .test("Is Phone Number Valid", "Phone number is not valid", (phoneNumberValue) => {
        if (!phoneNumberValue) {
          return false;
        }
        return phoneNumberValue && phoneNumberValue.countryCode && phoneNumberValue.areaCode && phoneNumberValue.phoneNumber;
      }),
    role: Yup.object()
      .shape({
        label: Yup.string().required("Role label is required"),
        value: Yup.string().required("Role value is required"),
      })
      .required("Role is required"),
    sub_distributor_id: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .when("role", {
        is: (role) => role && (role.value === "agent" || role.value === "sub-distributor"),
        then: (schema) =>
          schema.required("Sub Distributor is required").shape({
            label: Yup.string().required("Sub Distributor label is required"),
            value: Yup.string().required("Sub Distributor value is required"),
          }),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
  });

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(UserDetailsSchema),
    defaultValues,
    shouldFocusError: true,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (userData) {
      setDefaultValues({
        name: userData?.name || "",
        email_address: email || "",
        msisdn: userData?.msisdn || "",
        user_phone_number: userData?.msisdn  ? {
          countryCode: '+' + userData?.msisdn?.substring(0, 2),
          areaCode: userData?.msisdn?.substring(2, 5),
          phoneNumber: userData?.msisdn?.substring(5),
        } : null,
        role: {},
      });
    } else {
      setDefaultValues({
        name: "",
        email_address: email,
        msisdn: "",
        user_phone_number: {
          countryCode: "+27",
          areaCode: "",
          phoneNumber: "",
        },
        role: {},
      });
    }
    methods.reset(defaultValues);
  }, [userData]);

  let roles = [
    {
      label: "Distributor",
      value: "distributor",
    },
    {
      label: "Sub Distributor",
      value: "sub-distributor",
    },
    {
      label: "Support Agent",
      value: "support_agent",
    },
  ];

  if (selectedRole.role === "admin") {
    roles.push({
      label: "Referrer",
      value: "referrer",
    });
  }

  if (selectedRole.role === "admin" || selectedRole.role === "sub-distributor" || selectedRole.role === "distributor") {
    roles.push({
      label: "Agent",
      value: "agent",
    });
  }

  if (selectedRole.role === "sub-distributor") {
    roles = roles.filter((role) => role.value === "agent");
  }

  useEffect(() => {
    async function fetchSubDistributors() {
      if (!values.role || (values.role.value !== "agent" && values.role.value !== "sub-distributor")) {
        return;
      }
      try {
        const response = await getDistributorStores(distributor_i_customer);
        let subDistributors = response.data.map((subDistributor) => ({
          label: subDistributor.name,
          value: subDistributor.id,
          level: `Level ${subDistributor.level}`,
        }));

        if (selectedRole.role === "sub-distributor") {
          subDistributors = subDistributors.filter((subDistributor) => subDistributor.value === sub_distributor_id);
        }

        setSubDistributors(subDistributors);
        console.log("Sub Distributors: ", subDistributors);
      } catch (error) {
        console.error("Error fetching sub distributors:", error);
      }
    }

    fetchSubDistributors();
  }, [distributor_i_customer, selectedRole.role, sub_distributor_id, values.role]);

  const onProcessSubmit = useCallback(async () => {
    console.log("Values: ", values);

    const userPhoneNumber = `${values.user_phone_number.countryCode}${values.user_phone_number.areaCode}${values.user_phone_number.phoneNumber}`;

    const result = await addAppUserRoles({
      role: values.role.value,
      name: values.name,
      email_address: values.email_address,
      msisdn: values.msisdn,
      distributor_i_customer: selectedRole.role === "admin" ? distributor_i_customer : i_customer,
      user_phone_number: userPhoneNumber,
      sub_distributor_id: values.sub_distributor_id?.value,
    });

    if (result.status_code === 1001) {
      navigate(PATH_DASHBOARD.admin.appUsers);
      methods.setValue("name", "");
      methods.setValue("email_address", "");
      methods.setValue("msisdn", "");
      methods.setValue("role", {});

      enqueueSnackbar("User Role added successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.data.message, { variant: "error" });
    }
  }, [values, methods, enqueueSnackbar]);

  return (
    <>
      <Card sx={{ p: 3, mb: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onProcessSubmit)}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            {userData?.name && (
              <Grid item xs={6} sm={6}>
                <RHFTextField name="name" label="Name and Surname" InputLabelProps={{ shrink: true }} disabled={userData} />
              </Grid>
            )}
            <Grid item xs={6} sm={6}>
              <RHFTextField name="email_address" label="Email Address" InputLabelProps={{ shrink: true }} disabled={userData} />
            </Grid>
            {userData?.msisdn && (
              <Grid item xs={6} sm={6}>
                <RHFTextField name="msisdn" label="Phone Number" InputLabelProps={{ shrink: true }} disabled={userData} />
              </Grid>
            )}
            {userData?.msisdn == null && (
              <Grid item xs={12} sm={6}>
                <RHFPhoneInput name="user_phone_number" label="Phone Number" />
              </Grid>
            )}
            <Grid item xs={6} sm={6}>
              <RHFAutocomplete name="role" label="Select User Role" placeholder="User Role" options={roles} />
            </Grid>
            {values?.role?.value && (values.role.value === "agent" || values.role.value === "sub-distributor") && (
              <Grid item xs={6} sm={6}>
                <RHFAutocomplete
                  // disabled={selectedRole.role === "sub-distributor"}
                  name="sub_distributor_id"
                  label="Select Sub Distributor"
                  placeholder="Sub Distributor"
                  options={sub_distributors}
                  groupBy={(option) => option.level}
                  getOptionLabel={(option) => option.label}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
                <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onProcessSubmit)} disabled={errors.length > 0}>
                  Add
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </>
  );
};

export default AddAppUserRole;
