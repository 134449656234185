import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "../../components/snackbar";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// utils
// _mock_
// components
import { useSelector } from "react-redux";
import ConfirmDialog from "../../components/confirm-dialog";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import Iconify from "../../components/iconify";
import Label from "../../components/label";
import Scrollbar from "../../components/scrollbar";
import { useSettingsContext } from "../../components/settings";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  getComparator,
  useTable,
} from "../../components/table";
import IotDialog from "../../sections/@dashboard/iot/components/IotDialog";
import { IotRuleTableToolbar } from "../../sections/@dashboard/iot/list";

import { saveAs } from "file-saver";
import moment from "moment";
import Papa from "papaparse";

import { getCustomerBalance, getIotAccountList, getIotCustomers, getRuleSimCardData, topUpCustomerBalance } from "../../api/iot-api";

import { IotTableRow } from "src/sections/@dashboard/iot/list";
import LoadingScreen from "../../components/loading-screen";
import IotAddRuleDialog from "src/sections/@dashboard/iot/components/IotAddRuleDialog";
import IotAddSimDialog from "src/sections/@dashboard/iot/components/IotAddSimDialog";
import IotRuleTableRow from "src/sections/@dashboard/iot/list/IotRuleTableRow";
import { debounce } from "../../utils/decounce";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = ["all"];

const TABLE_HEAD = [
  { id: "rule_name", label: "Rule Name", align: "left" },
  { id: "rule_description", label: "Rule Description", align: "left" },
  { id: "rule_type", label: "Rule Type", align: "left" },
  { id: "starting_date", label: "Starting Date", align: "left" },
  { id: "rule_repetition", label: "Rule Repetition", align: "left" },
  { id: "MISDIN", label: "MSDIN", align: "left" },
  { id: "distributor_name ", label: "Distributor Name", align: "left" },
];

// ----------------------------------------------------------------------

export default function IotRulePage() {
  const theme = useTheme();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { distributor_i_customer: i_customer, role } = selectedRole;

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const [addRuleModalOpen, setAddRuleModalOpen] = useState(false);
  const [addSimModalOpen, setAddSimModalOpen] = useState(false);

  const handleAddRuleModalOpen = () => {
    setAddRuleModalOpen(true);
  };
  const handleAddRuleModalClose = () => {
    setAddRuleModalOpen(false);
  };
  const handleAddRuleModalConfirm = () => {};
  const handleAddSimModalOpen = () => {
    setAddSimModalOpen(true);
  };

  const handleAddSimModalClose = () => {
    setAddSimModalOpen(false);
  };
  const handleAddSimModalConfirm = () => {
    getFilteredData();
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  //* Table Code

  const {
    dense,
    page,
    order,
    orderBy,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    // onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  //Filters
  const [distributors, setDistributors] = useState([]);
  const [searchFilterText, setSearchFilterText] = useState("");
  const [distributor, setDistributor] = useState(null);

  const denseHeight = dense ? 56 : 76;

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
  });

  const isNotFound = (!dataFiltered.length && !!setSearchFilterText) || (!dataFiltered.length && !!setDistributor);

  // const isNotFound = true;
  const isFiltered = searchFilterText !== "" || distributor !== null;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleResetFilter = () => {
    setSearchFilterText("");
    setDistributor(null);
    getFilteredData();
  };

  const handleViewRow = (id, msisdn) => {
    // navigate(PATH_DASHBOARD.iot.view(id, msisdn));
  };
  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleDeleteRows = (selectedRows) => {
    const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === dataFiltered.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        if (searchValue?.length > 0) {
          setLoading(true);
          getFilteredData({ msisdnSearchText: searchValue }).finally(() => setLoading(false));
        } else {
          setTableData([]);
        }
      }, 300),
    []
  );

  // const handleSearchFilterText = (event, value) => {
  //   setPage(0);
  //   setSearchFilterText(event.target.value);
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       try {
  //         const distributorId = role == "admin" ? (distributor ? distributor?.value : null) : i_customer;
  //         const { data, status_code } = await getRuleSimCardData({
  //           limit: Number(rowsPerPage),
  //           page: Number(page) + 1,
  //           msisdnSearchText: event.target.value,
  //           distributor: distributorId,
  //         });
  //         if (status_code === 1001) {
  //           setTableData(data.data);
  //         } else {
  //           setTableData([]);
  //         }
  //       } catch (ex) {
  //         console.error("Error fetching Customer Balance: ", ex);
  //       }

  //       setLoading(false);
  //     } catch (ex) {
  //       console.error(ex);
  //       setLoading(false);
  //     }
  //   })();
  // };
  // const handleDistributorChange = (event, value) => {
  //   setPage(0);
  //   setDistributor(value);
  //   const currDistributor = value;
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       try {
  //         const distributorId = role == "admin" ? (currDistributor ? currDistributor?.value : null) : i_customer;
  //         const { data, status_code } = await getRuleSimCardData({
  //           limit: Number(rowsPerPage),
  //           page: Number(page) + 1,
  //         });
  //         if (status_code === 1001) {
  //           setTableData(data.data);
  //         } else {
  //           setTableData([]);
  //         }
  //       } catch (ex) {
  //         console.error("Error fetching Customer Balance: ", ex);
  //       }

  //       setLoading(false);
  //     } catch (ex) {
  //       console.error(ex);
  //       setLoading(false);
  //     }
  //   })();
  // };

  const getFilteredData = async (filter) => {
    try {
      setLoading(true);
      try {
        const distributorId = role == "admin" ? (distributor ? distributor?.value : null) : i_customer;
        let filterSearchData = {
          limit: Number(rowsPerPage),
          page: Number(page) + 1,
        };
        if (filter) {
          filterSearchData = {
            limit: Number(rowsPerPage),
            page: Number(page) + 1,
            [Object.keys(filter)[0]]: filter[Object.keys(filter)[0]],
          };
        }
        const { data, status_code } = await getRuleSimCardData(filterSearchData);

        if (status_code === 1001) {
          setTableData(data.data);
        } else {
          setTableData([]);
        }
      } catch (ex) {
        console.error("Error fetching Customer Balance: ", ex);
      }

      setLoading(false);
    } catch (ex) {
      console.error(ex);
      setLoading(false);
    }
  };

  const handleFilterChange = (type, value) => {
    if (type === "search") {
      debouncedSearch(value);
      setSearchFilterText(value);
    } else if (type === "distributor") {
      getFilteredData({ distributor: value.value });
      setDistributor(value);
    } else {
      getFilteredData();
    }
    setPage(0);
  };
  //* Loading Table Data
  useEffect(() => {
    getFilteredData();
  }, []);

  //* use effects
  useEffect(() => {
    (async () => {
      if (role == "admin") {
        const { status_code, data } = await getIotCustomers();
        if (status_code == 1001) {
          const distributorsOptions = data.map((distributor) => {
            return { ...distributor, value: distributor.i_customer, label: distributor.company_name };
          });
          setDistributors(distributorsOptions);
        }
      }
    })();
  }, []);

  return (
    <>
      <>
        <Helmet>
          <title> Rule </title>
        </Helmet>

        <Container maxWidth={themeStretch ? false : "xl"}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <CustomBreadcrumbs
              heading="Accounts"
              links={[
                {
                  name: "Dashboard",
                  href: PATH_DASHBOARD.root,
                },
                {
                  name: "Rule",
                },
              ]}
            />
            <Grid item xs={12} display="flex" width={200} alignItems="flex-start" justifyContent="space-between">
              <Button variant="contained" color="primary" onClick={handleAddRuleModalOpen}>
                Add Rule
              </Button>
              <Button variant="contained" color="primary" onClick={handleAddSimModalOpen}>
                Add Sim
              </Button>
            </Grid>
          </Stack>

          <IotAddRuleDialog open={addRuleModalOpen} handleClose={handleAddRuleModalClose} handleConfirm={handleAddRuleModalConfirm} />
          <IotAddSimDialog open={addSimModalOpen} handleClose={handleAddSimModalClose} handleConfirm={handleAddSimModalConfirm} />
          <Card>
            <Tabs
              sx={{
                px: 2,
                bgcolor: "background.neutral",
              }}
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <IotRuleTableToolbar
                isFiltered={isFiltered}
                onResetFilter={handleResetFilter}
                distributorsOptions={distributors}
                userRole={role}
                searchFilterText={searchFilterText}
                // onSearchFilterText={handleSearchFilterText}
                distributor={distributor}
                // onDistributorChange={handleDistributorChange}
                onFilterChange={handleFilterChange}
              />
            </div>
            {loading ? (
              <>
                <LoadingScreen />
              </>
            ) : (
              <TableContainer sx={{ position: "relative", overflow: "unset" }}>
                <TableSelectedAction
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  action={
                    <Stack direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon="ic:round-send" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon="eva:printer-fill" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={handleOpenConfirm}>
                          <Iconify icon="eva:trash-2-outline" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />

                <Scrollbar>
                  <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />

                    <TableBody>
                      {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <IotRuleTableRow
                          key={row.msisdn}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onViewRow={() => handleViewRow(row.i_customer, row.msisdn)}
                        />
                      ))}

                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            )}

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>

        <ConfirmDialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          title="Delete"
          content={
            <>
              Are you sure want to delete <strong> {selected.length} </strong> items?
            </>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows(selected);
                handleCloseConfirm();
              }}
            >
              Delete
            </Button>
          }
        />
      </>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  return inputData;
}
