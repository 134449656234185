import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { listAllProductTypes } from "src/api/megs-api-api";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "src/components/snackbar";
import ProductList from "src/sections/@dashboard/products/ProductList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const ProductsPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { distributor_i_customer: i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchProductTypes();
    })();
  }, []);

  const fetchProductTypes = async () => {
    const { data } = await listAllProductTypes();
    data.unshift({ id: "All", name: "All" });
    setProductTypes(data);
  };

  return (
    <>
      <Helmet>
        <title>App Users</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs heading="Products" links={[{ name: "Products", href: PATH_DASHBOARD.products.root }, { name: "Product List" }]} />
          </Grid>
        </Grid>

        <ProductList productTypes={productTypes} />
      </Container>
    </>
  );
};

export default ProductsPage;
